import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-touch-layout',
  templateUrl: './touch-layout.component.html',
  styleUrls: ['./touch-layout.component.scss']
})
export class TouchLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
