import { environment } from "../../environments/environment";

export const ApiEndpoints = {
  log: {
    getAll: () => `${environment.baseUrl}/Log`,
    post: () => `${environment.baseUrl}/Log/`,
    get: (id) => `${environment.baseUrl}/Log/${id}`,
    put: (id) => `${environment.baseUrl}/Log/${id}`,
    delete: (id) => `${environment.baseUrl}/Log/${id}/`,
  },
  group: {
    getAll: () => `${environment.baseUrl}/Group`,
    post: () => `${environment.baseUrl}/Group/`,
    get: (id) => `${environment.baseUrl}/Group/${id}`,
    put: (id) => `${environment.baseUrl}/Group/${id}`,
    delete: (id) => `${environment.baseUrl}/Group/${id}/`,
  },
  rule: {
    getAll: () => `${environment.baseUrl}/Rule`,
    getPairedRules: () => `${environment.baseUrl}/Rule/PairedRule`,
    post: () => `${environment.baseUrl}/Rule/`,
    get: (id) => `${environment.baseUrl}/Rule/${id}`,
    put: (id) => `${environment.baseUrl}/Rule/${id}`,
    delete: (id) => `${environment.baseUrl}/Rule/${id}/`,
  },
  blinder: {
    getAll: () => `${environment.baseUrl}/BlinderOptions`,
    post: () => `${environment.baseUrl}/BlinderOptions/`,
    get: (id) => `${environment.baseUrl}/BlinderOptions/${id}`,
    put: (id) => `${environment.baseUrl}/BlinderOptions/${id}`,
    delete: (id) => `${environment.baseUrl}/BlinderOptions/${id}/`,
  },
  dashboard: {
    getAll: () => `${environment.baseUrl}/Dashboard`,
    get: (id) => `${environment.baseUrl}/Dashboard/${id}/`
  },
  device: {
    getAll: () => `${environment.baseUrl}/Device`,
    getAllByType: (type) => `${environment.baseUrl}/Device?type=${type}`,
    post: () => `${environment.baseUrl}/Device/`,
    getDeviceMqtt: () => `${environment.baseUrl}/DeviceMqtt/`,
    get: (id) => `${environment.baseUrl}/Device/${id}`,
    put: (id) => `${environment.baseUrl}/Device/${id}`,
    delete: (id) => `${environment.baseUrl}/Device/${id}/`,
    setOptionGroup: () => `${environment.baseUrl}/Device/options/group`,
    deleteOptionGroup: () => `${environment.baseUrl}/Device/options/group/delete`,
    changeGroup: () => `${environment.baseUrl}/Device/options/group/change`,
    getGroupOptionById: (id) => `${environment.baseUrl}/Device/options/group/${id}/`,
    editOptionGroup: () => `${environment.baseUrl}/Device/options/group/edit`,
  },
  blinder_options: {
    getAll: () => `${environment.baseUrl}/BlinderOptions`,
    post: () => `${environment.baseUrl}/BlinderOptions/`,
    get: (id) => `${environment.baseUrl}/BlinderOptions/${id}`,
    put: (id) => `${environment.baseUrl}/BlinderOptions/${id}`,
    delete: (id) => `${environment.baseUrl}/BlinderOptions/${id}/`,
  },
  paired: {
    getAll: () => `${environment.baseUrl}/Paired`,
    post: () => `${environment.baseUrl}/Paired/`,
    get: (id) => `${environment.baseUrl}/Paired/${id}`,
    put: (id) => `${environment.baseUrl}/Paired/${id}`,
    delete: (id) => `${environment.baseUrl}/Paired/${id}/`
  },
  user: {
    getAll: () => `${environment.baseUrl}/Users`,
    get: (id) => `${environment.baseUrl}/Users/${id}`,
    put: (id) => `${environment.baseUrl}/Users/${id}`,
    delete: (id) => `${environment.baseUrl}/Users/${id}/`,
    register: () => `${environment.baseUrl}/Users/Register`,
    login: () => `${environment.baseUrl}/Users/Login/`,
  },
  switch: {
    switch: (identifier, btn, parameter) => `${environment.baseUrl}/Switch/${identifier}/${btn}/${parameter}`,
    switchBlinder: (identifier, btn, parameter, percentage) => `${environment.baseUrl}/SwitchBlinder/${identifier}/${btn}/${parameter}/${percentage}`,
  },
};
